<template>
  <div class="surveys">
    <div class="content">
      <div class="surveys__title">Опросы</div>
    </div>
    <Breadcrumbs :pages="[{ name: 'Опросы' }]" />
    <div class="content mt-8">
      <div
        class="surveys__group mb-8"
        v-if="filteredSurveys.active && filteredSurveys.active.length"
      >
        <input type="checkbox" checked id="group1" />
        <label
          for="group1"
          class="surveys__group-head"
          style="background-color: #f3f7cc"
        >
          <div class="surveys__group-title">Доступен</div>
          <div class="surveys__group-count" style="background-color: #d0de33">
            {{ filteredSurveys.active.length }}
          </div>
          <div class="surveys__group-arrow">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.25 8.25L12.75 15.75L5.25 8.25"
                stroke="#3C4242"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </label>

        <div class="surveys__group-items">
          <div
            class="surveys__item"
            v-for="(survey, ind) in filteredSurveys.active"
            :key="ind"
          >
            <div class="surveys__item-info">
              <div class="surveys__item-title" v-html="survey.name"></div>
              <div
                class="surveys__item-description"
                v-html="survey.description"
              ></div>
              <div class="surveys__item-date">
                Дата назначения: {{ formatDate(survey.valid_from_date) }}
              </div>
              <div class="surveys__item-date">
                Дата завершения: {{ formatDate(survey.deadline) }}
              </div>
            </div>

            <div
              class="
                button button_pink
                d-inline-flex
                px-8
                mt-xs-4
                ml-auto ml-xs-0
              "
              @click="openSurvey(survey.process_id)"
            >
              Пройти
            </div>
            <div
              class="surveys__item-frame-overlay"
              v-if="activeSurvey === survey.process_id"
            ></div>
            <div
              class="surveys__item-frame-container"
              v-if="activeSurvey === survey.process_id"
            >
              <div class="surveys__item-frame-close" @click="openSurvey(null)">
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 7L25 25"
                    stroke="#3C4242"
                    stroke-width="2"
                    stroke-linecap="square"
                  />
                  <path
                    d="M25 7L7 25"
                    stroke="#3C4242"
                    stroke-width="2"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
              <iframe
                class="surveys__item-frame"
                :src="`${survey.url}`"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div
        class="surveys__group mb-8"
        v-if="
          filteredSurveys.endedWithComment &&
          filteredSurveys.endedWithComment.length
        "
      >
        <input type="checkbox" id="group1" />
        <label
          for="group1"
          class="surveys__group-head"
          style="background-color: #f6cce2"
        >
          <div class="surveys__group-title">Завершён с замечаниями</div>
          <div class="surveys__group-count" style="background-color: #9a3374">
            {{ filteredSurveys.endedWithComment.length }}
          </div>
          <div class="surveys__group-arrow">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.25 8.25L12.75 15.75L5.25 8.25"
                stroke="#3C4242"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </label>

        <div class="surveys__group-items">
          <div
            class="surveys__item"
            v-for="(survey, ind) in filteredSurveys.endedWithComment"
            :key="ind"
          >
            <div class="surveys__item-info">
              <div class="surveys__item-title" v-html="survey.name"></div>
              <div
                class="surveys__item-description"
                v-html="survey.description"
              ></div>
              <div class="surveys__item-date">
                Дата назначения: {{ formatDate(survey.valid_from_date) }}
              </div>
              <div class="surveys__item-date">
                Дата завершения: {{ formatDate(survey.deadline) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="surveys__group"
        v-if="filteredSurveys.ended && filteredSurveys.ended.length"
      >
        <input type="checkbox" id="group1" />
        <label
          for="group1"
          class="surveys__group-head"
          style="background-color: #ebefee"
        >
          <div class="surveys__group-title">Завершён</div>
          <div class="surveys__group-count" style="background-color: #c4d0cd">
            {{ filteredSurveys.ended.length }}
          </div>
          <div class="surveys__group-arrow">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.25 8.25L12.75 15.75L5.25 8.25"
                stroke="#3C4242"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </label>

        <div class="surveys__group-items">
          <div
            class="surveys__item"
            v-for="(survey, ind) in filteredSurveys.ended"
            :key="ind"
          >
            <div class="surveys__item-info">
              <div class="surveys__item-title" v-html="survey.name"></div>
              <div
                class="surveys__item-description"
                v-html="survey.description"
              ></div>
              <div class="surveys__item-date">
                Дата назначения: {{ formatDate(survey.valid_from_date) }}
              </div>
              <div class="surveys__item-date">
                Дата завершения: {{ formatDate(survey.deadline) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
export default {
  name: "Surveys",
  data: () => ({
    activeSurvey: null,
  }),
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapGetters(["Surveys"]),
    filteredSurveys() {
      return {
        active: this.Surveys.filter((el) => el.status === "Доступен"),
        endedWithComment: this.Surveys.filter(
          (el) => el.status === "Завершён с замечаниями"
        ),
        ended: this.Surveys.filter((el) => el.status === "Завершён"),
      };
    },
  },
  methods: {
    ...mapActions(["fetchSurveys"]),
    formatDate(date) {
      let jsDate = new Date(date);
      return `${jsDate.getDate().toString().padStart(2, 0)}.${jsDate
        .getMonth()
        .toString()
        .padStart(2, 0)}.${jsDate.getFullYear()}`;
    },
    openSurvey(process_id) {
      this.activeSurvey = process_id;
      bus.$emit("scrollLock", !!process_id);
    },
  },
  async mounted() {
    await this.fetchSurveys();
    if (this.$route.query && this.$route.query.ProcessId) {
      this.openSurvey(this.$route.query.ProcessId);
    }
  },
};
</script>

<style lang="scss" scoped>
.surveys {
  padding-top: 64px;
  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    @media screen and (max-width: 600px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  &__group {
    &-head {
      padding: 8px 16px;
      border-radius: 4px 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;
    }
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
    }

    &-arrow {
      height: 100%;
      margin-left: auto;
      transition: 0.3s;
      & > svg {
        height: 100%;
      }
    }

    &-count {
      flex-shrink: 0;
      margin-right: 16px;
      margin-left: 16px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-items {
      max-height: 0;
      overflow: hidden;
      transition: 0.5s;
    }

    & > input {
      display: none;
      &:checked {
        & ~ .surveys__group-head {
          & .surveys {
            &__group {
              &-arrow {
                transform: rotate(180deg);
              }
            }
          }
        }
        & ~ .surveys__group-items {
          max-height: 100000px;
        }
      }
    }
  }
  &__item {
    padding: 16px 0;
    border-bottom: 1px solid #d2d2d2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    &-title {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      margin-bottom: 8px;
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 8px;
    }

    &-date {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 8px;
    }

    &-frame {
      width: 100%;
      height: 100%;
      &-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70vw;
        height: 80vh;
        z-index: 10002;
        background-color: #fff;
      }
      &-close {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
        position: absolute;
        top: -15px;
        right: -15px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > * {
          width: 90%;
          height: 90%;
        }
      }
      &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10001;
        background: rgba(31, 31, 31, 0.2);
      }
    }
  }
}
</style>